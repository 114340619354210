:root {
  --main-gradient: radial-gradient(circle, rgb(29, 28, 31) 0%, rgb(34, 35, 46) 100%);
}

* {
  /* scroll-margin-top: 10px; */
  border-radius: 9px;
}

#root {
  background-color: rgb(29, 28, 31);
}

#body {
  display: flex;
  flex-direction: row;
  background-color: rgb(29, 28, 31);
  background: var(--main-gradient);
  border-radius: 0px;
  /* min-width: fit-content;
  max-width: 100vw; */
}

#main-container {
  flex: 1 1;
  min-height: 100vh;
}

.jumbotron {
  border-radius: 0;
  background: url("./assets/background.jpg") no-repeat center fixed;
  -webkit-background-composite: cover;
  background-size: cover;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  color: whitesmoke;
  text-align: right;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  text-shadow: 2px 2px #000000;
  box-shadow: 0 2px 10px rgb(200, 200, 200);
}

.jumbotron h1 {
  line-height: 65px;
}

#banner-left {
  max-width: 650px;
  padding: 0 30px;
  font-size: 36px;
  margin: 0 2vw;
}

#banner-right {
  padding: 0 30px;
  margin: 0 2vw;
}

#banner-left h1 {
  font-size: 54px;
}

#profile-pic {
  /* object-fit: contain; */
  background-color: black;
  width: 400px;
  border-color: rgb(106, 115, 125);
  border-width: 6px;
  border-style: inset;
  border-radius: 8px;
  /* outline-color: black;
  outline-width: 3px;
  outline-style: solid; */
}

#main-content {
  /* z-index: 1020; */
  padding-top: 14px;
  /* box-shadow: -2px 0 10px rgb(200, 200, 200); */
}

#resume-container {
  width: fit-content;
  margin: 0 auto;
}

#blurb-container {
  display: flex;
  align-content: center;
  padding: .75rem;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
}

#blurb {
  flex: 0 1 800px;
  padding: .75rem;
  margin: 8px;
  background: whitesmoke;
  border-radius: 20px;
  border: 2px solid rgb(0, 225, 255);
}

#sidebar {
  flex: 0 1 600px;
  justify-self: flex-end;
  padding: .75rem;
  margin: 8px;
  background: whitesmoke;
  border-radius: 20px;
  border: 2px solid rgb(0, 225, 255);
}

#blurb p {
  font-size: 18px;
  padding: 10px;
  margin: auto;
}

#sidebar h5 {
  text-align: center;
}

.content-header {
  color: #DEDEDE;
  text-align: center;
  padding: 40px 0;
}

.projects-container {
  justify-content: center;
  align-items: center;
}

.project-container {
  display: flex;
  padding: 10px;
  margin: 20px auto;
  width: 95%;
  max-width: 1600px;
  background: whitesmoke;
  border-radius: 14px;
  border: 2px solid rgb(0, 225, 255);
}

.project-container:hover {
  background: radial-gradient(circle, rgb(232, 243, 255) 0%, rgb(232, 243, 255) 50%, rgb(166, 191, 216) 100%);
}

.project-container p {
  font-size: 18px;
}

.image-link {
  margin: auto 30px auto 10px;
  width: 30%;
  min-width: fit-content;
  max-width: 400px;
}

.project-image {
  max-width: 400px;
  outline-style: solid;
  outline-width: 3px;
  outline-color: black;
  border-style: solid;
  border-color: whitesmoke;
  border-width: 3px;
  border-radius: 12px;
}

.content-container {
  justify-self: right;
}

.content-container a {
  display: inline-block;
  max-width: fit-content;
}

.project-description {
  padding-top: 6px;
  font-size: 20px;
}

#contact-blurb {
  max-width: 700px;
  margin: auto;
}

form {
  max-width: 600px;
  margin: auto;
}

.form-group {
  margin: 12px auto;
  max-width: 95vw;
}

.form-group-narrow {
  width: 400px;
  max-width: 90vw;
}

textarea {
  height: 200px;
}

form .button {
  width: 90px;
  height: 40px;
  margin: 6px;
  color: #DEDEDE;
  background: radial-gradient(circle, rgba(46, 49, 52, 1) 0%, rgba(93, 20, 20, 1) 100%);
  outline-color: black;
  border-radius: 8px;
}

form .button:hover {
  color: #DEDEDE;
  background: radial-gradient(circle, rgb(67, 71, 75) 0%, rgb(80, 23, 23) 100%);
}

.github-element {
  float: right;
  font-weight: bolder;
}

.deployment-message {
  font-size: 17px;
  font-weight: bold;
}

.hyperlink-styling {
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
}

.hyperlink-styling:hover {
  color: #0a55c5;
}

#footer {
  background-color: black;
  min-height: fit-content;
  padding: 16px;
  border-top: 2px solid rgb(0, 225, 255);
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

#footer a {
  color: rgb(0, 225, 225);
}

#footer p {
  color: rgb(148, 148, 148);
  display: inline;
  font-size: 18px;
}

#footer span {
  color: rgb(0, 225, 225);
}

#footer-left {
  display: inline;
}

#footer-left p {
  margin-left: 40px;
}

#footer-right {
  display: inline;
  float: right;
}

#footer-right p {
  margin-right: 40px;
}

.s-toggle,
.s-b-toggle {
  display: none;
}

@media screen and (max-width: 1280px) {
  .jumbotron {
    flex-direction: column-reverse;
  }

  #banner-left {
    margin-top: 20px;
    text-align: center;
  }

  /* #pfp-container {
    display: none;
  }

  #banner-right {
    display: none;
  } */

  #blurb-container {
    display: block;
  }

  #sidebar {
    margin: 10px auto;
    padding: 30px;
    text-align: center;
    max-width: 95%;
  }
}

@media screen and (max-width: 992px) {
  .jumbotron {
    height: fit-content;
    padding-top: 20px;
  }

  #banner-left {
    font-size: 24px;
    text-align: center;
  }

  #banner-left h1 {
    font-size: 40px;
  }

  #banner-right {
    display: none;
  }

  .image-link {
    display: none;
  }

  #portfolio {
    margin: 0 20px;
    max-width: 1400px;
  }

  #footer-right {
    display: none;
  }

  .s-toggle {
    display: inline;
  }

  .s-b-toggle {
    display: block;
  }
}